<!-- eslint-disable max-len -->
<template>
  <svg viewBox="0 0 1147 1147" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1146.21 0H0V1146.21H1146.21V0Z" fill="#652D90" />
    <path
      d="M546.971 473.27C546.971 424.068 526.666 341.524 390.669 341.524H236.686L352.625 540.34H249.209V804.682H320.749V605.012H390.272L505.481 804.682H585.751L463.673 594.394C517.613 576.02 546.971 533.706 546.971 473.27ZM429.577 536.074L353.35 406.201H390.669C465.377 406.201 475.453 440.098 475.453 473.27C475.453 508.762 461.598 528.064 429.577 536.074Z"
      fill="white"
    />
    <path
      d="M866.385 573.109C894.691 550.539 909.52 516.543 909.52 473.294C909.52 424.092 889.204 341.553 753.207 341.553H611.753V406.219H753.202C827.909 406.219 837.99 440.127 837.99 473.294C837.99 506.462 827.909 540.364 753.202 540.364H611.753V605.914H753.202C827.909 605.914 837.99 639.812 837.99 672.984C837.99 706.157 827.909 740.054 753.202 740.054H611.753V804.691H753.202C889.199 804.691 909.515 722.142 909.515 672.94C909.515 629.662 894.706 595.67 866.381 573.124"
      fill="white"
    />
  </svg>
</template>
